import React, { useState, useRef } from 'react';
import { instantiateLiveFormApply } from 'helpers/hotwire_helpers/LiveFormHelper';
import closeIcon from '../../assets/images/close_button.svg';
import graduationSubmitButton from '../../assets/images/graduation-submit-button.png';

const EducationFilter = ({ existingSelectedOptions }) => {  
  const { 
    msiOnlyOption, 
    selectedUniversityNameOptions, 
    selectedDegreeOptions,
    selectedGraduationYearOption
  } = existingSelectedOptions;

  const universityNamesSearchRef = useRef(null);

  const [msiOnly, toggleMsiOnly] = useState(msiOnlyOption);
  const [selectedDegrees, setSelectedDegrees] = useState(selectedDegreeOptions || []);
  const [selectedUniversityNames, setSelectedUniversityNames] = useState(selectedUniversityNameOptions || [] ); 
  const [graduationYearInput, setGraduationYearInput] = useState(selectedGraduationYearOption || '');
  const [selectedGraduationYear, setSelectedGraduationYear] = useState(selectedGraduationYearOption || '');

  instantiateLiveFormApply(msiOnly);
  instantiateLiveFormApply(selectedDegrees);
  instantiateLiveFormApply(selectedUniversityNames);
  instantiateLiveFormApply(selectedGraduationYear);

  function handleCheckChange(option, event) {
    if (event.target.checked) {
      setSelectedDegrees([...selectedDegrees, option]);
    } else {
      setSelectedDegrees(selectedDegrees.filter(degree => degree !== option));
    }
  }


 function handleRemoveUniversity(option, event) {
  if (universityNamesSearchRef.current) {
    universityNamesSearchRef.current.value = '';
  }
  
  setSelectedUniversityNames(selectedUniversityNames.filter(name => name !== option));
 }
 
  const degreeOptions = [
    { name: "Associate degree", value: "associate"}, 
    { name: "Bachelor's degree", value: "bachelor"},
    { name: "Master's degree", value: "master"},
    { name: "Doctorate degree", value: 'doctor'},
    { name: "MD degree", value: 'medical_doctor'}
  ]

  const handleGraduationYearSubmit = () => {
    const year = parseInt(graduationYearInput);
    if (!isNaN(year) && year > -1) {
      setSelectedGraduationYear(year);
    }
  };

  return (
    <>
      <div className="toggle" style={{borderBottom: "1px solid #E5E5E5", paddingBottom: "10px", marginBottom: "0px"}}>
        <input type="checkbox"
               readOnly
               name={'msi_only'}
               value={msiOnly}
               checked={msiOnly}
               autoComplete="off" />

        <label checked="checked">
          <div className="toggle__slider" onClick={() => toggleMsiOnly(!msiOnly)} />

          Candidates from minority-serving institutions
        </label>
      </div>
      <div style={{borderBottom: "1px solid #E5E5E5", padding: "10px 0px"}}>
        {degreeOptions.map((degree) => (
          <div key={degree.value}>
            <label className="checkbox">
              <input type="checkbox"
                    name={'degrees[]'}
                    value={degree.value}
                    checked={selectedDegrees.includes(degree.value)}
                    onChange={(event) => handleCheckChange(degree.value, event)}
                    autoComplete="off" />
              {degree.name}
            </label>
          </div>
        ))}
      </div>
      <div style={{padding: "10px 0px 0px 0px"}}>
        <input
          type="text"
          placeholder="Search by college name"
          ref={universityNamesSearchRef}
          disabled={msiOnly}
          name="university_names[]"
          style={{marginBottom: "10px", padding: "8px", width: "100%", border: "1px solid #767776"}}
        />
        <div>
          <ul className='options-list'>
            { selectedUniversityNames.map((name) => (
              <li key={name}>
                <label className={msiOnly ? 'university-name-option disabled' : 'university-name-option'}>
                  <img src={closeIcon} className='close-icon' onClick={(event) => handleRemoveUniversity(name, event)} />
                  
                  <input type="checkbox" name={`university_names[]`} value={name} checked={selectedUniversityNames} />
                  { name }
                </label>
              </li>
            ))}
          </ul>
        </div>
        {/* TODO ADD BACK AFTER NEXT REINDEX (current date: 2/11/2025) */}
        {/* <div style={{padding: "10px 0px 0px 0px", borderTop: '1px solid #E5E5E5' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span>Graduated within the last</span>
            <input
              type="number"
              placeholder="#"
              value={graduationYearInput}
              onChange={(e) => setGraduationYearInput(e.target.value)}
              style={{
                width: '38px',
                height: '38px',
                padding: '8px',
                border: '1px solid #767776',
                textAlign: 'center',
                opacity: '50%',
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                margin: 0
              }}
              min="0"
              name="graduation_years_ago"
            />
            <span>years</span>
            <img 
              src={graduationSubmitButton} 
              style={{ width: '38px', height: '38px', cursor: 'pointer' }} 
              onClick={handleGraduationYearSubmit} 
              className='graduation-submit-button'
            />

          </div>
        </div> */}
      </div>
    </>
  );
}

export default EducationFilter;
