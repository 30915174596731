import React, { useState } from "react";
import { instantiateLiveFormApply } from "helpers/hotwire_helpers/LiveFormHelper";

const HideViewedCandidatesToggle = ({ existingSelectedOptions }) => {
  const [hideViewedCandidates, setHideViewedCandidates] = useState(existingSelectedOptions);

  instantiateLiveFormApply(hideViewedCandidates);

  return (
    <div className="toggle">
      <input type="checkbox"
             readOnly
             name="hide_viewed_candidates"
             value={hideViewedCandidates}
             checked={hideViewedCandidates}
             autoComplete="off" />

      <label checked="checked">
        <div className="toggle__slider" onClick={() => setHideViewedCandidates(!hideViewedCandidates)}></div>

        Hide viewed candidates
      </label>
    </div>
  )
};

export default HideViewedCandidatesToggle;
