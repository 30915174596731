import React, { useState } from "react";
import { instantiateLiveFormApply } from "helpers/hotwire_helpers/LiveFormHelper";

const HideSavedCandidatesToggle = ({ existingSelectedOptions, hideTeammatesCandidatesOptions }) => {
  const [hideSavedCandidates, setHideSavedCandidates] = useState(existingSelectedOptions);
  const [hideTeammatesCandidates, setHideTeammatesCandidates] = useState(hideTeammatesCandidatesOptions);

  instantiateLiveFormApply(hideSavedCandidates);
  instantiateLiveFormApply(hideTeammatesCandidates);

  const handleMainToggleChange = (value) => {
    setHideSavedCandidates(value);

    if (!!value) {
      setHideTeammatesCandidates(false);
    }
  };

  instantiateLiveFormApply(hideSavedCandidates);
  instantiateLiveFormApply(hideTeammatesCandidates);

  return (
    <>
      <div className="toggle">
        <input 
          type="checkbox"
          readOnly
          name="hide_saved_candidates"
          value={hideSavedCandidates}
          checked={hideSavedCandidates}
          autoComplete="off" 
        />
        <label checked="checked">
          <div 
            className="toggle__slider" 
            onClick={() => handleMainToggleChange(!hideSavedCandidates)}
          ></div>
          Hide saved candidates
        </label>
      </div>

      <div className="diversity-filter__checkbox-container" style={{ marginTop: '10px', marginLeft: '42px' }}>
        <input 
          id="hide_teammates_candidates"
          type="checkbox"
          name="hide_teammates_candidates"
          value={hideTeammatesCandidates}
          checked={hideTeammatesCandidates}
          onChange={(e) => hideSavedCandidates && setHideTeammatesCandidates(e.target.checked)}
          disabled={!hideSavedCandidates}
        />
        <label 
          htmlFor="hide_teammates_candidates"
          style={{ 
            opacity: hideSavedCandidates ? 1 : 0.5,
            cursor: hideSavedCandidates ? 'pointer' : 'not-allowed'
          }}
        >
          <div 
            className="diversity-filter__checkbox" 
          >
          </div>
          Hide teammates saved candidates
        </label>
      </div>
    </>
  );
};

export default HideSavedCandidatesToggle;
