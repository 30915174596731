import React from "react";
import usePersistedState from 'hooks/usePersistedState';
import LocationFilter from './LocationFilter';
import StudentFilter from './StudentFilter';
import BoardFilter from './BoardFilter';
import EducationFilter from './EducationFilter';
import Options from './Options';
import OpenToOpportunititesFilter from './OpenToOpportunitiesFilter';
import { handleToggleDrawer } from 'helpers/form_helpers/FilterMethods';
import { handleClearFilter } from 'helpers/form_helpers/FilterMethods';
import locationIcon from '../../assets/images/Location.svg';
import titleIcon from '../../assets/images/Title.svg';
import experienceIcon from '../../assets/images/Experience.svg';
import companyIcon from '../../assets/images/Company.svg';
import industryIcon from '../../assets/images/Industry.svg';
import studentIcon from '../../assets/images/Students.svg';
import headcountIcon from '../../assets/images/Headcount.svg';
import boardIcon from '../../assets/images/Boards.svg';
import openToOpportunitiesIcon from '../../assets/images/Opportunities.svg';
import educationIcon from '../../assets/images/education_filter.svg';
import excludeCompaniesIcon from '../../assets/images/exclude_companies.svg';

const getComponent = (componentName) => {

  switch(componentName) {
    case 'location':
      return LocationFilter;
    case 'student':
      return StudentFilter;
    case 'board':
      return BoardFilter;
    case 'open_to_opportunities':
      return OpenToOpportunititesFilter;
    case 'education':
      return EducationFilter;
    default: 
      return Options;
  }
};

const drawerIconLink = (drawerName) => {
  switch(drawerName) {
    case 'Location':
      return locationIcon;
    case 'Title':
        return titleIcon;
    case 'Students':
      return studentIcon;
    case 'Years of Experience':
      return experienceIcon;
    case 'Company':
      return companyIcon;
    case 'Industry':
      return industryIcon;
    case 'Company Headcount':
      return headcountIcon;
    case 'Boards':
      return boardIcon;
    case 'Likely Open to Opportunities':
      return openToOpportunitiesIcon;
    case 'Education':
      return educationIcon;
    case 'Exclude Company':
      return excludeCompaniesIcon;
    default: 
      return '';
  }
}

export default function Drawer(props) { 
  const { drawerName, displayClear, componentName } = props;
  const [drawerOpen, toggleDrawer] = usePersistedState(`${drawerName}DrawerToggle`, true);
  const drawerIsOpen = drawerOpen === 'true'  

  const Component = getComponent(componentName);

  return (
    <div className="drawer">
      <div className="drawer__icon">
          <img src={drawerIconLink(drawerName)} className="drawer__icon__svg" />
      </div>
      <div className={drawerIsOpen ? 'drawer__name open ' : 'drawer__name closed'}>
        <div className="drawer__header__container">
          { drawerName }
          { displayClear && <a onClick={handleClearFilter} className="drawer__name__clear">Clear</a> }
        </div>
        <summary onClick={() => handleToggleDrawer(drawerIsOpen, toggleDrawer)}></summary>
      </div>
      <section className={drawerIsOpen ? 'open ' : 'closed'}>
        { drawerIsOpen && 
          <Component {...props} />
        }
      </section>
    </div>
  )
}

